import { Component, Inject, PLATFORM_ID } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Gtag } from "angular-gtag";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: [ "./app.component.scss" ]
})
export class AppComponent {

    constructor(private translateService: TranslateService, @Inject(PLATFORM_ID) private platformId: {}, gtag: Gtag) {
        this.translateService.use("cs");
    }
}
