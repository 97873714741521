import { HttpClient } from "@angular/common/http";
import { makeStateKey, StateKey, TransferState } from "@angular/platform-browser";
import { Observable } from "rxjs";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { environment } from "src/environments/environment";
import { StateKeyGenerator } from "src/app/util/state-key-generator";

export class TranslateLoaderBrowser {
    constructor(private httpClient: HttpClient, private transferState: TransferState) {
    }

    public getTranslation(lang: string): Observable<any> {
        const key: StateKey<number> = makeStateKey<number>(
            StateKeyGenerator.key("util", "TranslateLoaderBrowser", lang)
        );
        const data = this.transferState.get(key, null);

        if (data) {
            return new Observable((observer) => {
                observer.next(data);
                observer.complete();
            });
        } else {
            const suffix = environment.production ? ".min.json" : ".json";
            return new TranslateHttpLoader(this.httpClient, "assets/i18n/", suffix).getTranslation(lang);
        }
    }
}

export function translateLoaderBrowserFactory(httpClient: HttpClient, transferState: TransferState): TranslateLoaderBrowser {
    return new TranslateLoaderBrowser(httpClient, transferState);
}
