import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";

const routes: Routes = [
    {
        path: "admin",
        loadChildren: () => import("./pages/admin/container/container.module").then(m => m.ContainerModule)
    },
    {
        path: "",
        loadChildren: () => import("./pages/public/public-routing/public-routing.module").then(m => m.PublicRoutingModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules, scrollPositionRestoration: "enabled", initialNavigation: "enabled"
        })
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {
}
