import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root"
})
export class MatPaginatorIntlService extends MatPaginatorIntl {

    public readonly changes = new Subject<void>();
    public itemsPerPageLabel = "";
    public nextPageLabel = "";
    public previousPageLabel = "";
    public firstPageLabel = "";
    public lastPageLabel = "";
    public getRangeLabel = (page: number, pageSize: number, length: number): string => {
        const translationKey = "service.matPaginator.rangeLabel";
        let startValue = (page * pageSize) + 1;
        let endValue = (page * pageSize) + pageSize;
        if (length === 0) {
            startValue = 0;
            endValue = 0;
        } else if (endValue > length) {
            endValue = length;
        }
        const translatedValue = this.translateService.instant(
            translationKey,
            {
                first: startValue,
                last: endValue,
                total: length
            });
        if (translatedValue === translationKey) {
            return "";
        } else {
            return translatedValue;
        }
    };

    constructor(private translateService: TranslateService) {
        super();
        this.updateTranslations();
        this.translateService.onLangChange.subscribe(() => {
            this.updateTranslations();
        });
    }

    private async updateTranslations(): Promise<void> {
        this.itemsPerPageLabel = await this.translateService.get("service.matPaginator.itemsPerPage").toPromise();
        this.nextPageLabel = await this.translateService.get("service.matPaginator.nextPage").toPromise();
        this.previousPageLabel = await this.translateService.get("service.matPaginator.previousPage").toPromise();
        this.firstPageLabel = await this.translateService.get("service.matPaginator.firstPage").toPromise();
        this.lastPageLabel = await this.translateService.get("service.matPaginator.lastPage").toPromise();
        this.changes.next();
    }
}
