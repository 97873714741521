import { BrowserModule, DomSanitizer, TransferState } from "@angular/platform-browser";
import { FormsModule }   from '@angular/forms';
import { LOCALE_ID, NgModule } from "@angular/core";
import { registerLocaleData } from "@angular/common";
import localeCs from "@angular/common/locales/cs";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { Driver, NgForageConfig } from "ngforage";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { TranslateLoader, TranslateModule, TranslateService } from "@ngx-translate/core";
import { TransferHttpCacheModule } from "@nguniversal/common";
import { translateLoaderBrowserFactory } from "src/app/util/translate-loader.browser";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { MatPaginatorIntlService } from "src/app/services/util/mat-paginator-intl/mat-paginator-intl.service";
import { MatDialogModule } from "@angular/material/dialog";
import { QuillModule } from "ngx-quill";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from "@angular/material-moment-adapter";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { GtagModule } from "angular-gtag";
import * as memoryDriver from "localforage-driver-memory";
import { LOCAL_FORAGE_CACHE_DURATION } from "src/app/util/constants";
import { environment } from "src/environments/environment";

registerLocaleData(localeCs);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: "serverApp" }),
        TransferHttpCacheModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatSnackBarModule,
        MatDialogModule,
        MatDatepickerModule,
        MatMomentDateModule,
        NgxMatSelectSearchModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (translateLoaderBrowserFactory),
                deps: [ HttpClient, TransferState ]
            },
            defaultLanguage: "cs"
        }),
        QuillModule.forRoot({
            format: "json",
            theme: "snow",
            modules: {
                toolbar: [
                    [ "bold", "italic", "underline", "strike" ], // Basic formatting
                    [ { align: [] } ], // Alignment
                    [ { header: 1 }, { header: 2 } ], // Header styles
                    [ { list: "ordered" }, { list: "bullet" } ], // Lists
                    [ { indent: "-1" }, { indent: "+1" } ], // Indents
                    [ { script: "sub" }, { script: "super" } ], // Subscript/superscript
                    [ "blockquote", "code-block" ], // Misc formats
                    [ "link", "image" ], // Embeds
                    [ { direction: "rtl" } ], // Text direction
                    [ "clean" ], // Formatting cleaner
                    [ "nbsp" ] // Custom non-breaking space blot
                ]
            }
        }),
        GtagModule.forRoot({ trackingId: "G-FWPQKJ5GV5", trackPageviews: false, debug: environment.debug })
    ],
    providers: [
        /*{
         provide: DEFAULT_CONFIG,
         useValue: {
         name: "ngSyrZdarma",
         driver: [
         Driver.INDEXED_DB,
         Driver.LOCAL_STORAGE
         ],
         cacheTime: LOCAL_FORAGE_CACHE_DURATION
         } as NgForageOptions
         },*/
        {
            provide: MatPaginatorIntl,
            useFactory: (translateService: TranslateService) => {
                return new MatPaginatorIntlService(translateService);
            },
            deps: [ TranslateService ]
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {
                strict: true,
                useUtc: true
            }
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: "cs-CZ"
        },
        {
            provide: LOCALE_ID,
            useValue: "cs"
        }
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {
    constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, ngForageConfig: NgForageConfig) {
        matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl("assets/mdi.svg"));
        ngForageConfig.defineDriver(memoryDriver).then(() => {
            ngForageConfig.configure({
                name: "ngSyrZdarma",
                driver: [
                    Driver.INDEXED_DB,
                    Driver.LOCAL_STORAGE,
                    memoryDriver._driver
                ],
                cacheTime: LOCAL_FORAGE_CACHE_DURATION
            });
        }).catch(() => {
            ngForageConfig.configure({
                name: "ngSyrZdarma",
                driver: [
                    Driver.INDEXED_DB,
                    Driver.LOCAL_STORAGE
                ],
                cacheTime: LOCAL_FORAGE_CACHE_DURATION
            });
            console.error("Couldn't define in-memory driver");
        });

    }
}
